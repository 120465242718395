import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import NavToggle from "./NavToggle";
import Navigation from "./Navigation";

import { heights, colors, breakpoints, spacers } from "../styles/variables";

const StyledHeader = styled.header`
  height: ${heights.header.mobile}px;
  background-color: ${colors.grey};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  padding: 0 ${spacers.mobile}px;

  @media screen and (min-width: ${breakpoints.tablet}px) {
    padding: 0 ${spacers.tablet}px;
  }

  @media screen and (min-width: ${breakpoints.desktop}px) {
    padding: 0 ${spacers.desktop}px;
    height: ${heights.header.desktop}px;
  }
`;

const HeaderInner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1792px;
  margin: 0 auto;

  > * {
    display: block;
    margin: auto 0;
  }
`;
const HeaderLogoMobile = styled.img`
  width: 186px;
  height: 46px;
  @media screen and (min-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

const HeaderLogo = styled.img`
  display: none;

  @media screen and (min-width: ${breakpoints.tablet}px) {
    display: block;
    width: 300px;
    height: 54px;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: 400px;
    height: 72px;
  }
`;

const Header: React.FC = () => {
  const [isNavOpen, setNavOpen] = useState(false);

  return (
    <>
      <StyledHeader>
        <HeaderInner>
          <Link to="/">
            <HeaderLogo src="/logo/logo_2025.svg" />
            <HeaderLogoMobile src="/logo/logo-mobile.svg" />
          </Link>
          <NavToggle isNavOpen={isNavOpen} setNavOpen={setNavOpen} />
        </HeaderInner>
      </StyledHeader>
      <Navigation isNavOpen={isNavOpen} setNavOpen={setNavOpen} />
    </>
  );
};

export default Header;
